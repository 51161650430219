<template>
  <moe-page title="查看优惠券">
    <moe-form :showBack="false">
      <div class="fwb mb-20 font-20">基本信息</div>

      <moe-describe-list title="" :col="1">
        <moe-describe-item label="活动名称"> {{ couponData.name }} </moe-describe-item>
        <moe-describe-item label="使用时间">
          <template v-if="couponData.timeType === 'TIME_RANGE'">{{ couponData.startTime }} 至 {{ couponData.endTime }}</template>
          <template v-if="couponData.timeType === 'RELATIVE'">{{ `领取后 ${couponData.effectiveDays} 天有效` }}</template>
        </moe-describe-item>
      </moe-describe-list>

      <div class="fwb mb-20 font-20">优惠信息</div>

      <moe-describe-list title="" :col="2">
        <moe-describe-item label="满"> {{ `${couponData.condition} 元` }} </moe-describe-item>
        <moe-describe-item label="减"> {{ `${couponData.discount} 元` }} </moe-describe-item>
        <moe-describe-item label="发行量"> {{ couponData.number }} </moe-describe-item>
        <moe-describe-item label="每人限领"> {{ `${couponData.quota === 0 ? '不限量' : couponData.quota}` }} </moe-describe-item>
      </moe-describe-list>
    </moe-form>

    <div style="height: 700px;min-height: 700px;" class="df fdc" v-if="IS_SHOP_COUPON">
      <moe-table
        :numberShow="false"
        :data="couponData.configList"
        :mode="false"
        :showPage="false"
        :params="{ pageNum: 1 }"
        emptyText="请添加活动商品">
        <el-table-column label="商品信息" min-width="200">
          <template slot-scope="{ row }">
            <div class="df aic">
              <moe-image :src="row.coverUrl" width="80px" height="80px" />
              <div style="text-align:left" class="ml-10">
                <p class="fwb">{{ row.name }}</p>
                <p class="font-12 color-info">商品编号: {{ row.goodsId }}</p>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="销售价" min-width="80">
          <template slot-scope="{ row }">
            {{ `￥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
          </template>
        </el-table-column>

        <el-table-column prop="stock" label="库存" min-width="80" />
      </moe-table>
    </div>

    <moe-form :showBack="false">
      <template slot="tool">
        <el-button icon="el-icon-back" @click="$moe_coordinator.navigateBack()">返回列表</el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManageCouponDetail',
  computed: {
    /** 是否为商品优惠券 */
    IS_SHOP_COUPON() {
      if (this.$route.query.type && this.couponData.level === 'PART') {
        return true
      } else {
        return false
      }
    },
  },
  data() {
    return {
      couponData: {}
    }
  },
  methods: {
    /** 获取优惠券详情 */
    getCouponDetail() {
      if (this.$route.query.id) {
        this.$moe_api.COUPON_API.getCouponDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let r = data.result;
            this.couponData = {
              id: r.id,
              name: r.name,
              timeType: r.timeType,
              startTime: r.startTime,
              endTime: r.endTime,
              effectiveDays: r.effectiveDays,
              condition: r.condition,
              discount: r.discount,
              number: r.number,
              quota: r.quota,
              level: r.level,
              configList: [],
            }

            if (r.configList.length) {
              this.getQueryShopGoodsByIds(r.configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(configList) {
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: configList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          this.couponData.configList = configList.map((item) => {
            let findGoodsItem = data.result.find(({ id }) => item.goodsId === id);
            return {
              ...findGoodsItem,
              goodsId: item.goodsId,
              id: item.id,
            }
          })
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.getCouponDetail();
  },
}
</script>

<style lang="scss">

</style>